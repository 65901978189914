<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Markisen / Wintergartenmarkise / Ancona <br />
    </h2>
    <div class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Glasdachmarkise - Ancona
      </h2>
      <div class="grid grid-cols-2">
        <div class="col-span-full lg:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>

        <div class="col-span-full lg:col-span-1">
          <h2
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Optional mit Vario Volant – für maximalen Komfort
          </h2>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Unter oder über dem Glas montiert ist die Glasdachmarkise Ancona die
            perfekte Lösung für alle, die Wintergarten oder Glasdach ganzjährig
            genießen möchten und dabei Wert auf einen praktischen und
            pflegeleichten Sonnenschutz legen. Bei Typ Unterglas bleibt die
            Markise trocken, sauber und gut geschützt vor Wind und Wetter. Zur
            Aufglas-Montage ist die Ancona mit schützender Dachabdeckung
            ausgestattet.<br /><br />
          </p>
        </div>
      </div>
      <div class="p-4">
        <h3 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h3>
        <tabs :mode="mode">
          <tab title="Typen">
            <p class="text-lg">
              <span class="font-bold w-full"
                >Erhältlich in verschiedene Produktvarianten</span
              >
              <br />
              <ul class="flex flex-col sm:flex-row">
                <li class="px-2 flex flex-col w-2/3">
                  <div class="w-1/3">
                    <img
                      src="../assets/img/lewens/ancona/lew-ancona-u-typ-orange-uli.webp"
                      style="min-width: 10rem"
                      alt="ancona glasmarkise unterglas"
                    />
                  </div>
                  <div class="lg:pl-16">Ancona Typ Unterglas</div>
                </li>
                <li class="px-2 flex flex-col w-2/3">
                  <div class="w-1/3">
                    <img
                      src="../assets/img/lewens/ancona/lew-ancona-a-typ-orange-uli.webp"
                      style="min-width: 10rem"
                      alt="ancona glasmarkise aufglas"
                    />
                  </div>
                  <span class="lg:pl-16">Ancona Typ Aufglas</span>
                </li>
              </ul>
            </p>
          </tab>
          <tab title="Maßen">
            <p class="text-lg">
              <span class="font-bold">Maßen</span> <br />
              <span class="font-bold">Breite:</span> <br />
              max. 600 cm - 1-teilig (Einzelfeld)<br />
              <span class="font-bold">Ausfall/Tiefe:</span> <br />
              max. 450 cm <br />
              <span class="font-bold">Antrieb:</span> <br />
              Motorantrieb <br />
              <span class="font-bold">Markisentücher:</span> <br />
              Marken-Acryl <br />
              Marken-Polyester (Option)
            </p>
          </tab>
          <tab title="Zertifizierung">
            <div class="text-lg">
              <span class="font-bold">Zertifizierung:</span> <br />
              <div class="flex flex-col">
                <div class="flex flex-row">
                  <div><img src="../assets/madeingermany.webp" alt="made in germany siegel" /></div>
                  <div class="w-full pl-1">
                    Hergestellt und montiert in Deutschland
                  </div>
                </div>
                <div class="flex flex-row">
                  <div><img src="../assets/tuev.webp" alt="tuev geprueft"/></div>
                  <div class="w-full pl-1">
                    TÜV-geprüfte Sicherheit
                  </div>
                </div>
              </div>
            </div>
          </tab>
        </tabs>
      </div>
    </div>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/lewens/ancona/lew-ancona-a-detail-terrasse-uli.webp"),
          alt:"glasdachmarkise ancona"
        },
        {
          image: require("@/assets/img/lewens/ancona/lew-ancona-u-innen-wintergarten-uli.webp"),
          alt:"glasdachmarkise innen ancona"
        },
        {
          image: require("@/assets/img/lewens/ancona/lew-ancona-u-vv-innen-terrasse-uli.webp"),
          alt:"glasdachmarkise innen auf terrasse"
        },
        {
          image: require("@/assets/img/lewens/ancona/modelle-web-rd-10.webp"),
          alt:"glasdachmarkise 3d gartenansicht"
        },
        {
          image: require("@/assets/img/lewens/ancona/lew-ancona-a-typ-orange-uli.webp"),
          alt:"ancona a typ orange"
        },
        {
          image: require("@/assets/img/lewens/ancona/lew-ancona-u-typ-orange-uli.webp"),
          alt:"ancona u typ orange"
        },
        {
          image: require("@/assets/img/lewens/ancona/lew-ancona-detail-dachabdeckung-xl.webp"),
          alt:"ancona detail dachabdeckung"
        },
        {
          image: require("@/assets/img/lewens/ancona/lew-ancona-detail-technik-xl.webp"),
          alt:"ancona detail technik"
        },
        {
          image: require("@/assets/img/lewens/ancona/lew-ancona-vv-detail-vvolant-orange-xl.webp"),
          alt:"ancona detail volant orange"
        },
      ],
    };
  },
};
</script>
